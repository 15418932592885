import React from 'react';
import Loader from '../components/Loader';
import _ajax from '../utils/_ajax';
import ReactGA from 'react-ga';

export default function withApi (endpoint, Component) {
    return class extends React.Component {

        constructor() {
            super();
            this.state = {
                data: null,
                dataLoaded: false,
                backgroundUrl: null,
                defaultBackground: null,
            };

            this.bg = React.createRef();
        }

        api(method, url, callback) {
            _ajax.call(this, {method, url}, callback);
        }

        changeBackground(backgroundUrl) {
            // console.log(backgroundUrl);
            this.setState({backgroundUrl: backgroundUrl});
        }

        componentDidMount() {
			ReactGA.initialize('UA-11523584-2');
			ReactGA.pageview(window.location.pathname);
            const self = this;
            const api = this.api.bind(this);

            api("GET", API_URL + endpoint, function() {
                let data = JSON.parse(this.response);

                let backgroundUrl;

                if (data.Video) {
                    backgroundUrl = data.Video;
                }
                else if (data.Image) {
                    backgroundUrl = data.Image;
                }
                else {
                    backgroundUrl = self.props.siteData.Background;
                }

                document.title = data.Title + ' | Open Creative Communications';

                self.setState({
                    data: data,
                    dataLoaded: true,
                    backgroundUrl: backgroundUrl,
                    defaultBackground: backgroundUrl,
                });
            });
        }

        render() {
            const data = this.state.data;
            const dataLoaded = this.state.dataLoaded;
            const backgroundUrl = this.state.backgroundUrl || this.state.defaultBackground;

            let background;

            if (dataLoaded) {

				if (backgroundUrl !== null) {
					if (backgroundUrl.slice(-3) === 'mp4') {
						background = (
                            <div
                                ref={this.bg}
                                className="opp-video--wrap opp-parallax">
                                <video
                                    className="opp-video"
                                    src={backgroundUrl}
                                    autoPlay
                                    playsInline
                                    muted
                                    loop></video>
                            </div>
							
						);
					}
					else {
						background = (<div
                            ref={this.bg}
						className="opp-background opp-parallax"
						style={{backgroundImage: `url(${backgroundUrl})`}}></div>);
					}
				}
				else {
					background = (<div
                        ref={this.bg}
						className="opp-background opp-parallax"
						style={{backgroundColor: `transparent`}}></div>);
				}
				

                return (
                    <div
                        className="opp-container">
                        {background}
                        <Component
                            data={data}
                            dataLoaded={dataLoaded}
                            changeBackground={this.changeBackground.bind(this)}
                            {...this.props} />
                    </div>
                );
            }
            else {
                return (<Loader />);
            }
        }
    }
}