import React from 'react';  

export default class Error extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <main
                className="opp-main">
                <div
                    className="opp-shutter--open">
                    </div>
                <div 
                    className="opp-content">
                    <div 
                        className="opp-content--text">
                        <h1 
                            className="opp-content--heading">404: Page not found.</h1>
                        <p>We can't find the page you're looking for.</p>
                    </div>
                </div>
            </main>
        );
    }
}