import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Components
import Nav from './Nav';
import Loader from './components/Loader';

// Pages
import Home from './pages/Home';

import Projects from './pages/Projects';
import SingleProject from './pages/Projects/SingleProject';

import Showreel from './pages/Showreel';
import Clients from './pages/Clients';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Sectors from './pages/Sectors';
import Error from './pages/Error';

export default class App extends React.Component {
    constructor() {
        super();
        this.state = {
            current: 'home',
            json: {},
            data: {},
            jsonLoaded: false,
            dataLoaded: false,
            menuOpen: false,
        };
    }

    componentDidMount() {

        var self = this;
    
        var request = new XMLHttpRequest();

        request.open("GET", API_URL + "GetSiteData");

        request.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                // console.log('response');
                let data = JSON.parse(this.response);
                // console.log(data);
                self.setState({data: data, dataLoaded: true});

            }
            else {
                // console.log(this);
            }
        };
        // console.log('request');

        request.send();
    }

    toggleNav(close) {
        close = close || false;
        let menuOpen = this.state.menuOpen;
		
		if (close) {
			this.setState({ menuOpen: false});
		}
		else {
			this.setState({ menuOpen: !menuOpen});
		}
    }

    render() {
        const { data, jsonLoaded, dataLoaded } = this.state;

        if (dataLoaded) {
            return (
                <BrowserRouter basename={BASENAME}>
                    <Route render={({location}) => {
                        // console.log(location);
                        return (
                        <div>
                            <Nav
                                menuOpen={this.state.menuOpen}
                                siteData={data}
                                toggleNav={this.toggleNav.bind(this)} />
                            <TransitionGroup
                                    childFactory={child => React.cloneElement(
                                        child, {
                                            classNames: (location.state !== undefined) ? location.state.transition : 'fade',
                                            timeout: (location.state !== undefined) ? 600 : 350
                                        }
                                    )}>
                                <CSSTransition
                                    key={location.pathname}
                                    timeout={350}
                                    classNames="fade">
                                    <div 
                                        className={this.state.menuOpen ? 'opp-blur' : ''}>
                                    <Switch location={location}>
                                        <Route exact={true} path="/" render={() => <Home siteData={data} />} />
                                        <Route path="/projects" render={() => <Projects siteData={data} />} />
                                        {/* <Route path="/projects/:title" render={() => <SingleProject siteData={data} />} /> */}
                                        <Route path="/showreel" render={() => <Showreel siteData={data} />} />
                                        <Route path="/clients" render={() => <Clients siteData={data} />} />
                                        <Route path="/services" render={() => <Services siteData={data} />} />
										<Route path="/sectors" render={() => <Sectors siteData={data} />} />
                                        <Route path="/contact" render={() => <Contact siteData={data} />} />
                                        <Route render={() => <Redirect to="/" />} />
                                    </Switch></div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    )}} />
                </BrowserRouter>
            );
        }
        else {
            return (
                <Loader />
            );
        }
        
    }
}