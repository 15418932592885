import React from 'react';

export default class Content extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div
            dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
        );
    }
}