import React from 'react';

export default class Window extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (

            <div>
                <header className="opp-header">
                    {this.props.header || ''}
                </header>
                <div
                    className="opp-window">
                    {this.props.children}
                </div>
                <footer className="opp-footer">
                    {this.props.footer}
                </footer>

            </div>
        );
    }
}