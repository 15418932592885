import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from './components/Logo';
import Social from './components/Social';

export default class Nav extends React.Component {
    constructor() {
        super();
        this.state = {
            menuData: [],
        };
    }

    componentDidMount() {

        var self = this;
        
        var request = new XMLHttpRequest();

        // debugger;
        request.open("GET", API_URL + "/GetMenuData");

        request.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                // console.log('response');
                let data = JSON.parse(this.response);
                // console.log(data);
                self.setState({menuData: data});

            }
            else {
                // console.log(this);

            }
        };
        // console.log('request');

        request.send();
    }

    toggleNav(close) {
		close = close === true ? true : false;
		// console.log(close);
        // console.log('toggleNav');
        const self = this;
		this.props.toggleNav(close);
    }

    render() {

        const { menuData } = this.state;
        const { siteData } = this.props;

        const navigation = menuData.map((item, index) => {
            const path = item.Path == 'home' ? '' : item.Path;
            return (
                <li className="opp-nav--menu--item" key={index}>
                    <NavLink
                        className="opp-nav--menu--item--link"
                        to={"/"+path}
                        exact
                        activeClassName="opp-nav--menu--item--link__active"
                        onClick={this.toggleNav.bind(this)}>{item.Title}</NavLink>
                </li>
            );
        });

        return (
            <nav
                className="opp-nav">
                <div className="opp-nav--bar">
                    <Link
                        to="/"
                        onClick={this.toggleNav.bind(this, true)}><Logo className="opp-logo" /></Link>
                    <button className={"opp-hamburger" + (this.props.menuOpen ? " opp-hamburger--close" : "")} onClick={this.toggleNav.bind(this)}>
                        <div className="opp-hamburger--line"></div>
                        <div className="opp-hamburger--line"></div>
                        <div className="opp-hamburger--line"></div>
                    </button>
                </div>

                

                <div className={"opp-nav--inner" + (this.props.menuOpen ? " opp-nav--open" : "")}>
                    <ul className="opp-nav--menu">
                        {navigation}
                    </ul>
                    
                    <div
                        className="opp-nav--contact">

                        <div
                            className="opp-nav--logo">
                            <Logo />
                        </div>

                        <h3>Location</h3>
                        <p>{siteData.Address}</p>

                        <h3>Contact</h3>
                        <p>
                            <a href={'mailto:' + siteData.Email}>{siteData.Email}</a>
                        </p>

                        <Social data={siteData} />
                    </div>
            </div>
            
        </nav>
        );
    }
}