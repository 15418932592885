import React from 'react';

// Components
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import withApi from '../../hoc/withApi';
import Shutter from '../../components/Shutter';

export default withApi('GetPage/showreel', class Showreel extends React.Component {
    constructor() {
        super();
        this.state = {
            data: '',
            dataLoaded: false,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
        });
    }

    render() {
        const { data, dataLoaded } = this.state;

        if (dataLoaded) {
            return (
                <Window>
                    <div
                        className="opp-showreel">
                        <Shutter />
                        <video src={data.Showreel} controls></video>
                    </div>
                </Window>
            );
        }
        else {
            return <Loader />;
        }
        
    }
});