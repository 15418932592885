import React from 'react';

export default class Loader extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div
                className="opp-shutter">
                <div></div>
            </div>
        );
    }
}