import React from 'react';

// Components
import Loader from '../../components/Loader';
// import ClientSlider from '../../components/ClientSlider';

// Utilities
import withApi from '../../hoc/withApi';
import Window from '../../components/Window';
import Shutter from '../../components/Shutter';
import Mouse from '../../components/Mouse';

export default withApi('/GetPage/clients', class Clients extends React.Component {
    constructor() {
        super();
        this.scrollTarget = React.createRef();
        this.state = {
            data: {},
            dataLoaded: false,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
        })
    }

    sideScroll(e) {
        // e.stopPropagation();
        const self = this.scrollTarget.current;
        // console.log(self.style.transform, self.offsetWidth, window.innerWidth);

        // console.log(window.matchMedia('(min-width: 640px)').matches);
        if (window.matchMedia('(min-width: 640px)').matches) {
            if (self.offsetWidth > window.innerWidth) {
                let newDY = (Math.sqrt(e.deltaY**2)/2 > 50 ? e.deltaY/2 : Math.sign(e.deltaY) * 50);

                // console.log(e.deltaY, newDY);
                // console.log(self.style.transform);

                let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g,''));
                // console.log({val});

                val = e.deltaY ? val - newDY : val;

                // console.log({val});

                if (val > 0) {
                    val = 0;
                }
                else if (val < (-1 * (self.offsetWidth - window.innerWidth))) {
                    val = (-1 * (self.offsetWidth - window.innerWidth));
                }

                self.style.transform = 'translateX('+val+'px)';
            }
        }
        else {
            self.style.transform = 'translateX(0)';
        }
    }

    prev() {
        const self = this.scrollTarget.current;
        const wrapWidth = (window.innerWidth - (6 * 16));

        let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g, ''), 10) + 500;
        // console.log(val);

        if (val > 0) {
            val = 0;
        }
        else if (val < (-1 * (self.offsetWidth - wrapWidth))) {
            val = (-1 * (self.offsetWidth - wrapWidth));
        }
        self.style.transform = 'translateX(' + val + 'px)';
    }

    next() {
        const self = this.scrollTarget.current;
        const wrapWidth = (window.innerWidth - (6 * 16));

        let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g, ''), 10) - 500;
        // console.log(val);

        if (val > 0) {
            val = 0;
        }
        else if (val < (-1 * (self.offsetWidth - wrapWidth))) {
            val = (-1 * (self.offsetWidth - wrapWidth));
        }
        self.style.transform = 'translateX(' + val + 'px)';
    }

    render() {
        const { data, dataLoaded } = this.state;

        if (dataLoaded) {
            const clientList = data.Clients.map((item, index) => (
                <div
                    className="opp-client"
                    key={index}>
                    <div
                        className="opp-client--inner"
                        style={{ backgroundImage: 'url(' + item.Image + ')' }}>
                    </div>
                </div>
            ));

            const footer = (
                <div className="opp-footer--projects mobile-hide">
                    <button
                        className="opp-footer--button"
                        onClick={this.prev.bind(this)}>
                        <img
                            style={{ transform: 'scaleX(-1)' }}
                            src="assets/right.svg"
                            alt="Right Arrow" />
                    </button>
                    <button
                        className="opp-footer--button"
                        onClick={this.next.bind(this)}>
                        <img
                            src="assets/right.svg"
                            alt="Right Arrow" />
                    </button>
                </div>
            );

            return (
                <Window footer={footer}>
                    <div
                        className="opp-main"
                        onWheel={this.sideScroll.bind(this)}>
                        <Shutter />
                        <div
                            className="opp-content">
                            <div
                                className="opp-clients--slider">
                                <h1
                                    className="opp-content--heading">{data.Title}</h1>
                                <div className="opp-projects--scroll">
                                    <Mouse />
                                    <div>Scroll</div>
                                    <img
                                        src="assets/right.svg"
                                        alt="Right Arrow" />
                                </div>
                                <div
                                    className="opp-client--list"
                                    ref={this.scrollTarget}
                                    style={{ transform: 'translate(0,0)' }}>{clientList}</div>
                            </div>
                        </div>
                    </div>
                </Window>
            );
        }
        else {
            return (
                <Loader />
            );
        }
    }
})