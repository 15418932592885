import React from 'react';
import Logo from './Logo';

export default class Loader extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div
                className="opp-loader">
                <div className="opp-spinner"></div>
            </div>
        );
    }
}