/**
 * Make an AJAX request
 * @param {object} params Request parameters
 * @param {function} callback Cullback function
 */
export default function (params, callback) {
    var xhr = new XMLHttpRequest();

    xhr.open(params.method, params.url, true);

    if (params.method === 'POST') {
        // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    }

    xhr.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            console.log(params.url);
            callback.call(this);
        }
        else {
            // console.log(this);
        }
    };
    console.log('xhr');

    xhr.send(params.data || null);
}