import React from 'react';

// Components
import withApi from '../../hoc/withApi';
import Project from './SingleProject';
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import Shutter from '../../components/Shutter';
import Mouse from '../../components/Mouse';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default withApi('GetPage/projects', class Projects extends React.Component {
    constructor() {
        super();
        this.scrollTarget = React.createRef();
        this.scrollTarget2 = React.createRef();
        this.state = {
            data: null,
            dataLoaded: null,
            hash: '',
			scrollPos: undefined,
            popup: false,
            currentProject: 0,
            pageState: undefined,
		};
		
		this.updateHash = this.updateHash.bind(this);
		this.sideScroll = this.sideScroll.bind(this);
    }

    componentDidMount() {
        let hash = window.location.hash;
        
        window.addEventListener("hashchange", this.updateHash, false);
        
        window.addEventListener('resize', this.sideScroll);

        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
			hash: hash,
			pageState: hash !== '' ? 'single' : 'list',
        });
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.updateHash);
        // window.removeEventListener("wheel", this.sideScroll, false);
        window.removeEventListener('resize', this.sideScroll);
    }

    updateHash() {
        const hash = window.location.hash;
        this.setState({
            hash: hash,
            pageState: hash !== '' ? 'single' : 'list',
        });
    }

    sideScroll(e) {
        // e.stopPropagation();
        const self = this.scrollTarget.current;
        // console.log(self.style.transform, self.offsetWidth, window.innerWidth);

        // console.log(window.matchMedia('(min-width: 640px)').matches);
        if (window.matchMedia('(min-width: 640px)').matches) {
            if (self.offsetWidth > window.innerWidth) {
                let newDY = (Math.sqrt(e.deltaY**2)/2 > 50 ? e.deltaY/2 : Math.sign(e.deltaY) * 50);

                // console.log(e.deltaY, newDY);
                // console.log(self.style.transform);

                let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g,''));
                // console.log({val});

                val = e.deltaY ? val - newDY : val;

                // console.log({val});

                if (val > 0) {
                    val = 0;
                }
                else if (val < (-1 * (self.offsetWidth - window.innerWidth))) {
                    val = (-1 * (self.offsetWidth - window.innerWidth));
                }

                self.style.transform = 'translateX('+val+'px)';
            }
        }
        else {
            self.style.transform = 'translateX(0)';
        }
    }

    togglePopup() {
        const popup = this.state.popup;
        this.setState({
            popup: !popup,
            pageState: popup ? 'single' : 'video',
        })
    }

    prev() {
        const self = this.scrollTarget.current;

        let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g,''),10) + 500;
        
        if (val > 0) {
            val = 0;
        }
        else if (val < (-1 * (self.offsetWidth - window.innerWidth))) {
            val = (-1 * (self.offsetWidth - window.innerWidth));
        }
        self.style.transform = 'translateX('+val+'px)';
    }
    
    next() {
        const self = this.scrollTarget.current;

        let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g,''),10) - 500;
        
        if (val > 0) {
            val = 0;
        }
        else if (val < (-1 * (self.offsetWidth - window.innerWidth))) {
            val = (-1 * (self.offsetWidth - window.innerWidth));
        }
        self.style.transform = 'translateX('+val+'px)';
	}

    render() {
        const {data, dataLoaded, hash, popup, pageState} = this.state;

        if (dataLoaded) {
			// Projects List
            const projectList = data.Projects.map((item, index) => (
                <div 
                    className="opp-projects--item" key={index}>
                    <div 
                        className="opp-projects--item--text" style={{backgroundImage: `url(${item.Image2})`}}>
                        <h2 className="opp-projects--item--heading">{item.Title}</h2>
						<div className="opp-projects--item--button--wrap">
                            <p className="opp-projects--item--description">{item.Description}</p>
							<a href={'#' + item.Hash} 
								className="opp-projects--item--button">View Project</a>
						</div>
                    </div>
                    <div 
                        className="opp-projects--item--image" style={{backgroundImage: `url(${item.Image})`}}>
                    </div>
                </div>
            ));

            const active = data.Projects.find(item => '#' + item.Hash === hash);
			
			// Active Project
            const activeProject = hash !== '' ? (
                <CSSTransition
                    timeout={300}
                    classNames="fade">
                    <Project ref={this.scrollTarget2} data={active} togglePopup={this.togglePopup.bind(this)} />
                </CSSTransition>
            ) : '';

            const closeButton = hash !== '' ? (
				<button
					className="opp-project--close"
					onClick={() => {window.location.hash = ''}}>
					&times;
				</button>
			) : '';

            const videoPopup = popup ? (
				<div
					className="opp-project--popup">
					<button
						className="opp-project--close"
						onClick={this.togglePopup.bind(this)}>
						&times;
					</button>
					<video
                        src={active.Video}
                        controls
                        autoPlay></video>
				</div>
            ) : '';
            
            let footer;

            switch(pageState) {

                case 'single':
                    // console.log('single');
                    footer = (
                    <div className="opp-footer--projects">
                        <button
                            className="opp-footer--button"
                            onClick={() => {window.location.hash = ''}}>
                            All Projects
                        </button>
                    </div>
                    );
                    break;

                case 'video':
                    // console.log('video');
                    footer = (
                    <div className="opp-footer--projects">
                        <button
                            className="opp-footer--button"
                            onClick={this.togglePopup.bind(this)}>
                            Close Video
                        </button>
                    </div>
                    );
                    break;

                default:
                    // console.log('default');
                    footer = (
                    <div className="opp-footer--projects mobile-hide">
                        <button
                            className="opp-footer--button"
                            onClick={this.prev.bind(this)}>
                            <img
                                style={{transform: 'scaleX(-1)'}}
                                src="assets/right.svg"
                                alt="Right Arrow" />{' '}Prev
                        </button>
                        <button
                            className="opp-footer--button"
                            onClick={this.next.bind(this)}>
                            Next{' '}
                            <img
                                src="assets/right.svg"
                                alt="Right Arrow" />
                        </button>
                    </div>
                    );
            }

            // console.log(footer);

            return (
                <Window footer={footer}>
                    <main
                        className="opp-projects">
                        <Shutter />
                        <div
                            className="opp-projects--list"
                            onWheel={this.sideScroll.bind(this)}
                            ref={this.scrollTarget}
                            style={{ transform: 'translate(0,0)' }}>
                            <div
                                className="opp-projects--intro">
                                <div
                                    className="opp-content--text">
                                    <h1
                                        className="opp-content--heading">{data.Title}</h1>
                                    <div className="opp-projects--scroll">
                                        <Mouse />
                                        <div>Scroll</div>
                                        <img
                                            src="assets/right.svg"
                                            alt="Right Arrow" />
                                    </div>
                                </div>
                            </div>
                            {projectList}
                        </div>
                        <TransitionGroup>
                            {activeProject}
                        </TransitionGroup>
						{closeButton}
						{videoPopup}
                    </main>
                </Window>
            );
        }
        else {
            return (
                <Loader />
            );
        }
    }
});