import React from 'react';

import withApi from '../../hoc/withApi';

// Components
import Content from '../../components/Content';
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import Shutter from '../../components/Shutter';
import Social from '../../components/Social';

import _ajax from '../../utils/_ajax';

export default withApi('GetPage/contact', class Contact extends React.Component {
	constructor() {
		super();
		this.state = {
			data: null,
			dataLoaded: null,
			success: false,
		};
		this.refInnerContent = React.createRef();
	}

	componentDidMount() {
		this.setState({
			data: this.props.data,
			dataLoaded: this.props.dataLoaded,
		});
		this._isMounted = true;
		this._maybeResizeContentBound = this._maybeResizeContent.bind(this);
		this._maybeResizeContentBound();
		window.addEventListener("resize", this._maybeResizeContentBound, false);
		this._timeout = setTimeout(this._maybeResizeContentBound, 100);
	}

	componentWillUnmount() {
		delete this._isMounted;
		clearTimeout(this._timeout);
		delete this._timeout;
		window.removeEventListener("resize", this._maybeResizeContentBound, false);
	}

	_maybeResizeContent() {
		if (!this._isMounted) {
			return;
		}
		if (!this.refInnerContent.current) {
			return;
		}
		this.setState({
			isSmall: this.refInnerContent.current.scrollHeight + 100 > window.innerHeight,
		});
	}

	render() {
		const { data, dataLoaded, success } = this.state;
		if (dataLoaded) {
			return (
				<Window>
					<main className="opp-main">
						<Shutter />
						<div className={"opp-content" + (this.state.isSmall ? " opp-content__small" : "")}>
							<div className="opp-content--text" ref={this.refInnerContent}>
								<h1 className="opp-content--heading">{data.Title}</h1>
								<Content
									className="opp-content--blurb"
									html={data.Content} />
								<Social data={this.props.siteData} />
							</div>
						</div>
						
					</main>
				</Window>
			);
		}
		else {
			return <Loader />;
		}
	}
});