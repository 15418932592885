import React from 'react';
import { Facebook, Instagram, Twitter, Linkedin } from 'react-feather';

export default class Loader extends React.Component {
    constructor() {
        super();
    }

    render() {
        let social = Object.entries(this.props.data.Social);
        social = social.map((item, index) => {
            let SocialIcon;
            switch(item[0]) {
                case 'Facebook':
                    SocialIcon = Facebook;
                    break;
                case 'Instagram':
                    SocialIcon = Instagram;
                    break;
                case 'Twitter':
                    SocialIcon = Twitter;
                    break;
                case 'LinkedIn':
                    SocialIcon = Linkedin;
                    break;
            }
            if (item[1] !== null) {
                return <li key={index}><a href={item[1]} title={item[0]}><SocialIcon /></a></li>;
            }
        });
        return (
            <ul className="opp-social">
                {social}
            </ul>
        );
    }
}