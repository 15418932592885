import React from 'react';
import Transition from 'react-transition-group/TransitionGroup';

// Components
import withApi from '../../hoc/withApi';
import Content from '../../components/Content';
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import Shutter from '../../components/Shutter';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default withApi('GetPage/sectors', class Sectors extends React.Component {
    constructor() {
        super();
        this.state = {
            data: null,
            hash: '',
            dataLoaded: false,
			sectorListOpen: false,
			tablet: false,
        };
    }

    componentDidMount() {
		let hash = window.location.hash;
		
		this.updateScreen.call(this);

        window.addEventListener("hashchange", this.updateHash.bind(this), false);
        window.addEventListener("resize", this.updateScreen.bind(this), false);

        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
            hash: hash,
        });
    }

    componentWillUnmount() {
		window.removeEventListener('hashchange', this.updateHash.bind(this));
		window.removeEventListener("resize", this.updateScreen.bind(this), false);
    }

    updateHash() {
        const hash = window.location.hash;

        let active = this.props.data.Sectors.find(item => { 
            return '#' + item.Hash === hash;
        }) || this.props.data;

        this.props.changeBackground(active.Image);
        this.setState({ hash: hash });
        if (this.state.sectorListOpen) {
            this.toggleMenu();
        }
	}
	
	updateScreen() {
		if (window.matchMedia('(max-width: 960px)').matches) {
			this.setState({tablet:true});
		}
		else {
			this.setState({tablet:false});
		}
	}

    toggleMenu() {
        const sectorListOpen = this.state.sectorListOpen;
        this.setState({ sectorListOpen: !sectorListOpen});
    }

    render() {
        const {data, dataLoaded, hash, tablet} = this.state;

        if (dataLoaded) {

            const sectorList = data.Sectors.map((item, index) => (
                <li 
                    className="opp-services--item" key={index}>
                    <a 
                        href={"#" + item.Hash}
                        className={`opp-services--item--link${ ('#'+item.Hash === hash ? ' opp-services--item--link__active' : '')}`}>
                        {item.Title}
                    </a>
                </li>
            ));
    
            let active = data.Sectors.find(item => { 
                // let itemHash = item.Hash;
                // console.log({ itemHash, hash })
                return '#' + item.Hash === hash;
            }) || data;

			// console.log(active);
    
            const activeSector = (
                <CSSTransition
                    key={active.Hash}
                    timeout={300}
                    classNames="swipe">
                    <div 
                        className="opp-content--text">
                        <h1 
                            className="opp-content--heading">{active.Title}</h1>
                        <Content html={active.Description || active.Content} />
                    </div>
                </CSSTransition>
            );
            
			let mainContent;

			if (!tablet) {
				mainContent = (
					<TransitionGroup>
						{activeSector}
					</TransitionGroup>
				);
			}
			else {
				mainContent = data.Sectors.map((item, index) => (
					<div 
						key={item.Hash}
                        className="opp-content--text">
                        <h1 
                            className="opp-content--heading">{item.Title}</h1>
                        <Content html={item.Description || item.Content} />
                    </div>
				));
			}

            const sectorListClass = 'opp-services--list' + (this.state.sectorListOpen ? ' opp-services--list__open' : '');

            return (
                <Window>
                    <main
                        className="opp-main">
                        <Shutter />
                        <div
                            className="opp-services">
                            <ul className={sectorListClass}>
                                {sectorList}
                            </ul>
							{mainContent}
                        </div>
                    </main>
                </Window>
            );
        }
        else {
            return (
                <Loader />
            );
        }
    }
})