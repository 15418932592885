import React from 'react';

import Content from '../../components/Content';

export default class Project extends React.Component {
    constructor() {
        super();
        this.scrollTarget = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.sideScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.sideScroll.bind(this));
    }

    sideScroll(e) {
        // e.stopPropagation();
        const self = this.scrollTarget.current;
        // console.log(self.style.transform, self.offsetWidth, window.innerWidth);

        // console.log(window.matchMedia('(min-width: 640px)').matches);
        if (window.matchMedia('(min-width: 640px)').matches) {
            if (self.offsetWidth > window.innerWidth) {
                let newDY = (Math.sqrt(e.deltaY**2)/2 > 50 ? e.deltaY/2 : Math.sign(e.deltaY) * 50);

                // console.log(e.deltaY, newDY);
                // console.log(self.style.transform);

                let val = parseInt(self.style.transform.replace(/[^0-9.-]+/g,''));
                // console.log({val});

                val = e.deltaY ? val - newDY : val;

                // console.log({val});

                if (val > 0) {
                    val = 0;
                }
                else if (val < (-1 * (self.offsetWidth - window.innerWidth))) {
                    val = (-1 * (self.offsetWidth - window.innerWidth));
                }

                self.style.transform = 'translateX('+val+'px)';
            }
        }
        else {
            self.style.transform = 'translateX(0)';
        }
    }

    showVideo() {
        this.props.togglePopup();
    }

    render() {
        const { data } = this.props;
        // console.log(data);

        return (
            <div
                className="opp-project"
                onWheel={this.sideScroll.bind(this)}
                ref={this.scrollTarget}
                style={{transform: 'translate(0,0)'}}>
                <div
                    className="opp-project--block">
                    <h1 className="opp-project--heading">{data.Title}</h1>
					<h2
                        className="opp-project--heading2">The Brief</h2>
                    <Content html={data.Brief} />
					<div
						className="opp-project--highlights">
						<h2
                            className="opp-project--heading2">Highlights</h2>
						<Content html={data.Highlights} />
					</div>
                </div>
                <div
                    className="opp-project--video--thumb"
                    onClick={data.Video? this.showVideo.bind(this) : ''}
                    style={{backgroundImage: `url(${data.VideoImage})`}}>
                    {data.Video? <img src="assets/playbutton.png" alt=""/> : ''}
                </div>
				<div
					className="opp-project--results">
					<div
                    className="opp-project--block">
                    <h2
                        className="opp-project--heading2">The Result</h2>
                        <Content html={data.Results} />
                    <div 
                        className="opp-project--image" style={{backgroundImage: `url(${data.Image2})`}}></div>
                    </div>
				</div>
            </div>
        );
    }
}